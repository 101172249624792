import { hasNames } from "../utils";
// import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";



import LinkedIn from "../images/linkedin.icon.svg";

import * as grd from "../styles/grid.module.scss";
import * as styles from "../styles/team-member-modal.module.scss";
import * as typ from "../styles/typography.module.scss";

const TeamMemberModal = ({
	image,
	name,
	position,
	children,
	isPreview = false,
	linkedin = null,
}) => {
	return (
		<div
			className={hasNames(
				styles.wrap,
				grd.gridContainer,
				isPreview ? styles.isPreview : null
			)}>
			<div className={hasNames(styles.image, grd.col5, grd.colSm12)}>
				{image}
			</div>
			<div className={hasNames(styles.nameBlock, grd.col7, grd.colSm12)}>
				<h2 className={styles.name}>{name}</h2>
				<div className={hasNames(styles.position, typ.eyebrow)}>
					<div className={styles.posActual}>{position}</div>
					{linkedin === null ? null : (
						<a href={linkedin} target="_blank" rel="noreferrer">
							<LinkedIn />
						</a>
					)}
				</div>
			</div>
			<div className={hasNames(styles.copyBlock, grd.col7, grd.colSm12)}>
				{children}
				{/* These are hard coded because they will be references to other bits of data and it's going to be a while thing and we should have the CMS sort first. */}
				{/* <div className={styles.partnersBar}>
					<StaticImage
						key="temp-1"
						src="../images/fallback-image.png"
						width={100}
						height={100}
						alt="placeholder"
					/>
					<StaticImage
						key="temp-2"
						src="../images/fallback-image.png"
						width={100}
						height={100}
						alt="placeholder"
					/>
					<StaticImage
						key="temp-3"
						src="../images/fallback-image.png"
						width={100}
						height={100}
						alt="placeholder"
					/>
					<StaticImage
						key="temp-4"
						src="../images/fallback-image.png"
						width={100}
						height={100}
						alt="placeholder"
					/>
					<StaticImage
						key="temp-5"
						src="../images/fallback-image.png"
						width={100}
						height={100}
						alt="placeholder"
					/>
				</div> */}
			</div>
		</div>
	);
};

export default TeamMemberModal;
